import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import { ProgressBar } from "react-loader-spinner";
import { logo } from "../data/index";

const Loading = (props) => {
	return (
		<div className="loaderHolder">
			<div className="loaderMain">
				<img src={logo} alt="site logo" />
				<LinearProgress className="mt-4" />
			</div>
		</div>
	);
};

export const IsLoadingHOC = (WrappedComponent, loadingMessage) => {
	function HOC(props) {
		const [isLoading, setLoading] = useState(false);

		const setLoadingState = (isComponentLoading) => {
			setLoading(isComponentLoading);
		};

		return (
			<>
				{isLoading && <Loading message={loadingMessage} />}
				<WrappedComponent {...props} setLoading={setLoadingState} />
			</>
		);
	}
	return HOC;
};

export default IsLoadingHOC;
