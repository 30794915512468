import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadExternalScriptFile from "../hooks/LoadExternalScriptFile";
import ReactGA from "react-ga";
const GoogleAnalytics = (props) => {
	const location = useLocation();
	const isJsonString = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};
	const campaignConfig = useSelector(({ app }) => app.config);
	useEffect(() => {
		if (campaignConfig.googleAnalyticsId) {
			let selected_lang = props.selected_lang;
			if (campaignConfig?.additionalDomain?.length > 0) {
				let hostName = window.location.origin;
				let data = campaignConfig.additionalDomain.filter((v) => v.domain.toLowerCase() === hostName.toLowerCase());
				if (data.length > 0) {
					let lang = data[0].language.toLowerCase();
					if (lang === "fr") {
						selected_lang = lang;
					} else {
						selected_lang = "en";
					}
				}
			}
			let googleAnalyticsId = isJsonString(campaignConfig.googleAnalyticsId) ? JSON.parse(campaignConfig.googleAnalyticsId)[selected_lang.toUpperCase()] : campaignConfig.googleAnalyticsId;
			googleAnalyticsId = googleAnalyticsId ? googleAnalyticsId : campaignConfig.googleAnalyticsId;
			ReactGA.initialize(googleAnalyticsId);
			ReactGA.set({ page: window.location.href });
			ReactGA.pageview(window.location.pathname);
		}
	}, [campaignConfig, location]);
	function gtag() {
		window.dataLayer.push(arguments);
	}

	const loadAnalyticsId = () => {
		window.dataLayer = window.dataLayer || [];
		try {
			if (campaignConfig.googleAnalyticsId) {
				let selected_lang = props.selected_lang;
				if (campaignConfig?.additionalDomain?.length > 0) {
					let hostName = window.location.origin;
					let data = campaignConfig.additionalDomain.filter((v) => v.domain.toLowerCase() === hostName.toLowerCase());
					if (data.length > 0) {
						let lang = data[0].language.toLowerCase();
						if (lang === "fr") {
							selected_lang = lang;
						} else {
							selected_lang = "en";
						}
					}
				}
				let googleAnalyticsId = isJsonString(campaignConfig.googleAnalyticsId) ? JSON.parse(campaignConfig.googleAnalyticsId)[selected_lang.toUpperCase()] : campaignConfig.googleAnalyticsId;
				googleAnalyticsId = googleAnalyticsId ? googleAnalyticsId : campaignConfig.googleAnalyticsId;
				gtag("js", new Date());
				gtag("config", googleAnalyticsId);
			}
		} catch (err) {
			console.log("err", err);
		}
	};
	if (campaignConfig.googleAnalyticsId) {
		let selected_lang = props.selected_lang;
		if (campaignConfig?.additionalDomain?.length > 0) {
			let hostName = window.location.origin;
			let data = campaignConfig.additionalDomain.filter((v) => v.domain.toLowerCase() === hostName.toLowerCase());
			if (data.length > 0) {
				let lang = data[0].language.toLowerCase();
				if (lang === "fr") {
					selected_lang = lang;
				} else {
					selected_lang = "en";
				}
			}
		}
		let googleAnalyticsId = isJsonString(campaignConfig.googleAnalyticsId) ? JSON.parse(campaignConfig.googleAnalyticsId)[selected_lang.toUpperCase()] : campaignConfig.googleAnalyticsId;
		googleAnalyticsId = googleAnalyticsId ? googleAnalyticsId : campaignConfig.googleAnalyticsId;
		return <LoadExternalScriptFile parentCallback={loadAnalyticsId} url={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />;
	} else {
		return <></>;
	}
};

export default GoogleAnalytics;
