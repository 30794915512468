import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
// import SignupScreen from "../SignupScreen/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ParticipateInstructions from "../../components/ParticipateInstructions";
const Home = (props) => {
	const { t, i18n } = useTranslation();
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			{config.campaignState !== "partialExpired" && <ParticipateInstructions />}
		</>
	);
};

export default IsLoadingHOC(Home, "");
