import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "./../IsLoadingHOC";
import { receiptListAction } from "./../../redux/actions";
import TableReport from "./../TableReport";
const PreviousReceiptList = (props) => {
	const { t } = useTranslation();
	const [tableList, setTableList] = useState([]);
	const columns = [
		{ dataField: "ind", text: "#" },
		{ dataField: "date", text: t("Date") },
		{ dataField: "status", text: t("Status") },
		{ dataField: "qualifyingAmount", text: t("Qualifying Amount") },
		{ dataField: "brand", text: t("Brand") },
		{ dataField: "points", text: t("Points") },
	];
	useEffect(() => {
		props.setLoading(true);
		const allMonths = ['Jan','Feb','Mar', 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		receiptListAction()
			.then((responseData) => {
				if (responseData.httpCode === 200 && responseData.data.content) {
					const respData = responseData.data.content.map((v, ind) => {
						let dt = new Date(v.date);
						let itemDate=`${allMonths[dt.getMonth()]} ${dt.getDate()>9?"":"0"}${dt.getDate()}, ${dt.getFullYear()}`;
						let d = JSON.parse(JSON.stringify(v));
						d.date = itemDate;
						d.qualifyingAmount = d.qualifyingAmount === 0 ? "-" : d.qualifyingAmount;
						d.brand = d.brandName ? d.brandName : "-";
						d.points = d.processPoints ? d.processPoints : "-";
						d.ind = ind + 1;
						return d;
					});
					respData.sort((a, b) => {
						return new Date(b.date) - new Date(a.date);
					});
					setTableList(respData);
				} else {
					setTableList([]);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setTableList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		// eslint-disable-next-line
	}, [props.reloadCounter]);
	if (tableList.length > 0) {
		return (
			<div className="w-100">
				<div className="validate-purchase-div">
					<div className="mb-4">
						<Row xs={1} md={1} lg={1}>
							<h4 className="h2-title mt-5 mb-4">
								<Trans>Previous Receipts Status</Trans>
							</h4>
						</Row>
						<Row>
							<Col xs lg="12" className="text-center mya_table">
								<TableReport searchTextboxEnable={false} keyField="id" data={tableList} columns={columns} />
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	} else {
		return "";
	}
};

export default IsLoadingHOC(PreviousReceiptList, "Loading....");
