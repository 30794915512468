import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
// import Register from "../screen/Signup/Register";
import Home from "../screen/Home";
import PrivateRoute from "./PrivateRoute";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
import FaqScreen from "../screen/FaqScreen";
import ThankYou from "../screen/ValidatePurchase/ThankYou";
import SignupScreen from "../screen/SignupScreen";
import EarnPointsPreview from "../screen/Earnpoints/EarnPointsPreview";
import EarnPoints from "../screen/Earnpoints/EarnPoints";
import NPNScreen from "../components/Npn";

import ParticipateInstructionsScreen from "../screen/ParticipateInstructionsScreen/index";
const routes = (
	<Switch>
		<AppRoute exact path="/" component={Home} layout={PublicLayout} />
		<AppRoute exact path="/register" component={SignupScreen} layout={PublicLayout} />
		<PrivateRoute exact path="/upload-receipt" component={UploadReceipt} layout={PublicLayout} />
		<Route exact path="/upcoming" component={Upcoming} />
		<Route exact path="/expired" component={Expired} />
		<AppRoute exact path="/support" component={SupportScreen} layout={PublicLayout} />
		<AppRoute exact path="/htp-instruction" component={ParticipateInstructionsScreen} layout={PublicLayout} />
		<AppRoute exact path="/faq" component={FaqScreen} layout={PublicLayout} />
		<AppRoute exact path="/thankyou" component={ThankYou} layout={PublicLayout} />
		{/* <PrivateRoute exact path="/choose-exp" component={EarnPoints} layout={PublicLayout} />
		<PrivateRoute exact path="/npn/:id" component={EarnPointsPreview} layout={PublicLayout} /> */}
		<AppRoute exact path="/npn" component={NPNScreen} layout={PublicLayout} />
	</Switch>
	// </>
);
export default routes;
