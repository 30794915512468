import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkText } from "../LinkText";
const Footer = () => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	return (
		<footer>
			<Container className="footer">
				<div className="footer-link mb-3" aria-label="footerMenu">
					<ul>
						<li>
							<a href={t("FOOTER.TNC_URL")} target="new">
								{t("FOOTER.TNC_COPY")}
							</a>
						</li>
						|
						<li>
							<a href={t("FOOTER.PRIVACY_URL")} target="new">
								{t("FOOTER.PRIVACY_COPY")}
							</a>
						</li>
						|
						<li>
							<a href={t("FOOTER.CONTACT_URL")} target="new">
								{t("FOOTER.CONTACT_COPY")}
							</a>
						</li>
					</ul>
				</div>
				<div className="copy-text">
					<div dangerouslySetInnerHTML={{ __html: t("FOOTER.COPY_DECK") }} />
					<p className="tierlogic text-center">
						<span className="d-block d-sm-inline-block text-center">
							{t("FOOTER.POWERED_BY")}
							<br></br>
							<a href="https://www.3tierlogic.com/" target="_blank">
								<img alt="3TL logo" src="/public/images/3tl-logo-footer.png" height="30px" />
							</a>
						</span>
					</p>
				</div>
			</Container>
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
