import React, { Fragment } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
export default ({ children }) => (
	<Fragment>
		<a className="skiptocontent" href="#main">
			skip to content
		</a>
		<div className="skin-size side-color">
			<Header />
			<main id="main">{children}</main>
			<Footer />
		</div>
	</Fragment>
);
