import React from "react";
import ParticipateInstructions from "../../components/ParticipateInstructions";
import { useTranslation } from "react-i18next";
const ParticipateInstructionsScreen = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<ParticipateInstructions />
		</>
	);
};

export default ParticipateInstructionsScreen;
