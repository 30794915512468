import React from "react";
import UploadReceiptImage from "../../components/ValidatePurchase/UploadReceiptImage";
import { useTranslation } from "react-i18next";
const UploadReceipt = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<UploadReceiptImage />
		</>
	);
};

export default UploadReceipt;
