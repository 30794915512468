import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import common_fr from "./translations/fr_language_bundle.json";
import common_es from "./translations/sp_language_bundle.json";
import common_en from "./translations/en_language_bundle.json";
let lang = "en";
if (localStorage.getItem("i18nextLng")) {
	lang = localStorage.getItem("i18nextLng");
} else {
	var userLang = navigator.userLanguage || navigator.language;
	const languages = ["en", "fr"];
	switch (userLang) {
		case "en-GB":
			lang = "en";
			break;
		case "fr-CA":
			lang = "fr";
			break;
		case "es":
			lang = "es";
			break;
		default:
			lang = "en";
	}
}
const languages = ["en", "fr"];
const resources = {
	en: {
		translation: JSON.parse(JSON.stringify(common_en)),
	},
	fr: {
		translation: JSON.parse(JSON.stringify(common_fr)),
	},
	es: {
		translation: JSON.parse(JSON.stringify(common_es)),
	},
};
// const resources = {
// 	en: {
// 		translation: common_en,
// 	},
// 	fr: {
// 		translation: common_fr,
// 	},
// };
const detectOptions = {
	order: ["querystring", "navigator"],
	lookupQuerystring: "lng",
};
i18n.use(initReactI18next)
	.use(XHR)
	.use(LanguageDetector)
	.init({
		// detection: options,
		detection: { order: ["path", "navigator"] },
		fallbackLng: "en",
		lng: lang,
		resources,
		whitelist: languages,
		debug: false,
		// keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});
i18n.changeLanguage();
export default i18n;
