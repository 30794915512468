import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/theme.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import routes from "./routing/Routing";
import "./child-theme/css/theme-child.css";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import { uiconfig, getIdentities } from "./redux/actions";
import { ToastContainer } from "react-toastify";
import GoogleAnalytics from "./components/GoogleAnalytics";
import RouteChangeTracker from "./RouteChangeTracker";
import DynamicPopupMessage from "./screen/DynamicPopupMessage";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import IsLoadingHOC from "./components/IsLoadingHOC";
const App = ({ uiconfig, getIdentities, setLoading }) => {
	const { i18n } = useTranslation();
	useEffect(() => {
		if (setLoading) {
			setLoading(true);
		}
	}, []);
	useEffect(() => {
		(async () => {
			uiconfig()
				.then((response) => {
					if (response.data) {
						if (response.data.googleAnalyticsId) {
							ReactGA.initialize(response.data.googleAnalyticsId);
						}
						if (response.data?.additionalDomain?.length > 0) {
							let hostName = window.location.origin;
							let data = response.data.additionalDomain.filter((v) => v.domain.toLowerCase() === hostName.toLowerCase());
							if (data.length > 0) {
								let lang = data[0].language.toLowerCase();
								if (lang === "fr") {
									i18n.changeLanguage(lang);
								}
							}
						}
					}
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		})();
	}, [uiconfig]);
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 10000);
	}, []);
	return (
		<>
			<Router>
				{routes}
				<RouteChangeTracker />
				<DynamicPopupMessage />
				<GoogleAnalytics selected_lang={i18n.language} />
			</Router>
			<ToastContainer />
		</>
	);
};
export default connect(null, { uiconfig, getIdentities })(IsLoadingHOC(App, "..."));
