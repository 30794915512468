import React, { useState, useEffect } from "react";
import { Row, FormGroup } from "react-bootstrap";
// eslint-disable-next-line
import { signup, login, preRegistrationAction } from "../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "./IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import Calendar from "react-calendar";
import Required from "./Required";
import Captcha from "./Captcha";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import Spinner from "react-bootstrap/Spinner";
var validator = require("validator");
const Signup = ({ signup, setLoading, login }) => {
	// const Signup = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [place, setPlace] = useState({});
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [companyList, setCompanyList] = useState([]);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	let [loadingEnable, setLoadingEnable] = useState(false);

	React.useEffect(() => {
		console.log("build", "13 Dec 20:21");
		if ((confige?.captchaConfig?.recaptchaVersion || "").toLowerCase() === "v3") {
			const interval = setInterval(() => {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
			}, 45000);
			return () => clearInterval(interval);
		}
	}, [resetCaptchaCnt, confige]);
	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			errorArray[name] = t("");
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
			errorArray["captcha"] = "REGISTER.ERROR.TNC_CHECKBOX";
		}
		setError(errorArray);
		setData(d);
	};

	const newsLetterChkHandler = (checked, name) => {
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
		}
		setData(d);
	};

	const errorMessages = {
		firstName: { reqMsg: "REGISTER.ERROR.FIRST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.FIRST_NAME_INVALID_MSG" },
		lastName: { reqMsg: "REGISTER.ERROR.LAST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.LAST_NAME_INVALID_MSG" },
		email: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		confirmEmail: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		city: { reqMsg: "REGISTER.ERROR.CITY_REQ_MSG", invalidMsg: "REGISTER.ERROR.CITY_INVALID_MSG" },
		state: { reqMsg: "REGISTER.ERROR.STATE_REQ_MSG", invalidMsg: "REGISTER.ERROR.STATE_INVALID_MSG" },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		city: "",
		state: "",
		latitude: 0,
		longitude: 0,
		additionalInfo: { visitorId: "", requestId: "", agree_to_terms: false, newsletter_subscribe: false, language: selected_lang, domain: window.location.origin },
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", lastName: "", email: "", confirmEmail: "", city: "", state: "", agree_to_terms: "" });
	useEffect(() => {
		let items = {};
		if (confige.campaignState === "partialExpired") {
			history.push(`/`);
			toast.error(t("COMMON.PARTIAL_EXPIRED_MSG"));
		}
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}

		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MIN_LENGTH", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MAX_LENGTH", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);
	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";

			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MIN_LENGTH", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MAX_LENGTH", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
				}
			}
			errorArray[name] = errorMessage;

			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("REGISTER.ERROR.EMAIL_MATCH");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = "REGISTER.ERROR.CAPTCHA_VAL";
		}
		if (!data.additionalInfo.agree_to_terms) {
			errorArray["agree_to_terms"] = "REGISTER.ERROR.TNC_CHECKBOX";
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			newData["additionalInfo"]["language"] = i18n.language.toUpperCase();

			setLoadingEnable(true);
			// props.setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					console.log("PreRegistration");
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoadingEnable(false);
							// props.setLoading(false);
						}
					} else {
						toast.error(t("COMMON.SOMTHING_WRONG"));
						setLoadingEnable(false);
						// props.setLoading(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid)
						.then((resp) => {
							toast.success(t("REGISTER.FIELDS.SUBMIT.SUCCESS"));
							history.push(`/upload-receipt`);
							//history.push(`/choose-exp`);
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoadingEnable(false);
							if (captchaEnable) {
								setResetCaptchaCnt(resetCaptchaCnt + 1);
							}

							// props.setLoadingEnable(false);
						})
						.catch((err) => {
							toast.error(`${t("COMMON.SOMTHING_WRONG")}`);
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoadingEnable(false);
							// props.setLoading(false);
						});
				}
			} catch (error) {
				console.log("errror", error);
				setLoadingEnable(false);
				// props.setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("COMMON.SOMTHING_WRONG"));
				}
			}
		}
	};

	return (
		<div id="enternow" className="register-page">
			<div className="col-sm-9">
				<form role="form" aria-label="Registration">
					<h1 className="mt-4 section-heading">{t("Register Now")}</h1>
					{/* <div>
						<p className="text-center">{t("REGISTER.sub_heading")}</p>
						<p className="text-center">{t("REGISTER.sub_heading1")}</p>
					</div> */}
					<Row className="mt-5">
						{/*[START:FIRSTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-firstname">
							<label htmlFor="first-name" className="col-form-label">
								{t("REGISTER.FIELDS.FIRST_NAME")}
								{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-firstName"
									name="firstName"
									onChange={handleChange}
									className="form-control"
									id="first-name"
									aria-label="Your First Name"
									// placeholder={t("lang_key_fname")}
								/>
								<span className="input-error">{t(error.firstName)}</span>
							</div>
						</div>
						{/*[END:FIRSTNAME]*/}

						{/*[START:LASTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="last-name" className="col-form-label">
								{t("REGISTER.FIELDS.LAST_NAME")}
								{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-lastName"
									name="lastName"
									onChange={handleChange}
									className="form-control"
									id="last-name"
									aria-label="Your Last Name"
									// placeholder={t("lang_key_fname")}
								/>
								<span className="input-error">{t(error.lastName)}</span>
							</div>
						</div>
						{/*[END:LASTNAME]*/}
					</Row>
					<Row>
						{/*[START:EMAIL]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email" className="col-form-label">
								{t("REGISTER.FIELDS.EMAIL")}
								{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
							</label>
							{/* {t("lang_key_email_confirm")} */}
							<div className="">
								<input type="email" data-test="register-email" name="email" onChange={handleChange} className="form-control" id="email" placeholder="" aria-label="Enter Email" aria-autocomplete="email" />
								<span className="input-error" role="alert">
									{t(error.email)}
								</span>
							</div>
						</div>
						{/*[END:EMAIL]*/}
						{/*[START:EMAIL CONFIRM]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email-confirm" className="col-form-label">
								{t("REGISTER.FIELDS.CONFIRM_EMAIL")}
								{validation["confirmEmail"] && validation["confirmEmail"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" data-test="register-email" name="confirmEmail" onChange={handleChange} className="form-control" id="email-confirm" placeholder="" aria-label="Re-enter Email" aria-autocomplete="" />
								<span className="input-error" role="alert">
									{t(error.confirmEmail)}
								</span>
							</div>
						</div>
						{/*[END:EMAIL CONFIRM]*/}
					</Row>
					<Row>
						<div className="mt-3 col-12">
							<div className="btm-1 d-flex v-align-baseline form-check">
								<input type="checkbox" id="agree_to_terms" name="agree_to_terms" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_terms")} />
								{selected_lang == "en" ? (
									<label htmlFor="agree_to_terms" className="form-check-label bold">
										Accept our{" "}
										<a href="https://bimbocanada.com/privacy-policy" target="new" className="alink">
											Privacy Policy
										</a>{" "}
										and{" "}
										<a href="/public/termsandconditions_en.pdf" target="new" className="alink">
											Terms and Conditions
										</a>
										.
										<Required />
										<br />
									</label>
								) : (
									<label htmlFor="agree_to_terms" className="form-check-label bold">
										Acceptez notre{" "}
										<a href="https://bimbocanada.com/fr/politique-de-confidentialite" target="new" className="alink">
											politique de confidentialité
										</a>{" "}
										et{" "}
										<a href="/public/termsandconditions_fr.pdf" target="new" className="alink">
											termes et conditions
										</a>
										.
										<Required />
										<br />
									</label>
								)}
							</div>
							<span className="input-error">{t(error.agree_to_terms)}</span>
						</div>
					</Row>

					{/*[START:GOOGLECAPTCHA]*/}
					{captchaEnable ? (
						<div className="form-group row mt-5">
							<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
								Google captcha
							</label>
							<div className="col-sm-12">
								<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
								<p className="input-error" style={{ marginTop: "-12px" }}>
									{" "}
									{t(error.captcha)}
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/*[END:GOOGLECAPTCHA]*/}
					<div className="mt-3 text-center">
						<button type="button" id="register-submit-btn" disabled={loadingEnable} data-test="register-submit" onClick={onSubmit} className={`btn btn-primary px-16 py-${loadingEnable ? 3 : 4} my-10`}>
							{loadingEnable ? <Spinner animation="border" className="orange" variant="warning" /> : t("REGISTER.FIELDS.SUBMIT.LABEL")}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default connect(null, { signup, login })(IsLoadingHOC(Signup, "Wait ....."));
// export default IsLoadingHOC(Signup, "Wait .....");
