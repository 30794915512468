import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const GoogleCaptchaV2 = (props) => {
	const [captchaRef, setCaptchaRef] = useState(null);
	const config = useSelector((state) => state.app.config);
	const [captchaSiteKey, setCaptchaSiteKey] = useState(null);
	useEffect(() => {
		if (config.captchaConfig.recaptchaSiteKey) {
			setCaptchaSiteKey(config.captchaConfig.recaptchaSiteKey);
		}
	}, [config, config.captchaConfig]);
	useEffect(() => {
		if (captchaRef) {
			captchaRef.reset();
		}
		// eslint-disable-next-line
	}, [props]);
	return <p align={props.align ? props.align : "center"}>{captchaSiteKey ? <ReCAPTCHA hl={props.lang} key={props.lang} ref={(r) => setCaptchaRef(r)} sitekey={captchaSiteKey} onChange={(value) => props.parentCallback(value)} /> : ""}</p>;
};
export default React.memo(GoogleCaptchaV2, (prevProps, curProps) => prevProps.reset === curProps.reset && prevProps.lang === curProps.lang);
