import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { pointsUpdate, surveyDetail, userSurveyFeedback, getCompletedActivity } from "../../redux/actions";
import { TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox } from "@material-ui/core";
import Required from "../Required";
import { toast } from "react-toastify";
const QuizQuestions = (props) => {
	const activityData = props.activityData;
	const setLoading = props.setLoading;
	const history = useHistory();
	let { id } = useParams();
	const { t, i18n } = useTranslation();
	const [surveyStatus, setSurveyStatus] = useState({ status: false, activityEventId: "none" });
	const [isPointsApiCalled, setIsPointsApiCalled] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState({});
	const [quizTag, setQuizTag] = useState("");
	const [discription, setDiscription] = useState(null);
	const [totalEntries, setTotalEntries] = useState("");
	const [dailyEntries, setDailyEntries] = useState("");
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({
		npnAnswer: "",
	});
	useEffect(() => {
		getTotalEntriesCount();
		let title = props.activityData["title"] ? props.activityData["title"] : "";
		console.log("TITLE", title);
		let desc = props.activityData["description"] ? props.activityData["description"] : "";
		console.log("DESCRIPTION", desc);
		if (title.toUpperCase() !== desc.toUpperCase()) {
			setDiscription(desc);
		}
	}, [props]);
	useEffect(() => {
		setLoading(true);
		let data = {
			reference: id,
			state: "started",
		};

		console.log("Data from Quiz Call", data);
		props
			.surveyDetail(activityData.data)
			.then((res) => {
				if (res.data) {
					const questions = [...res.data.questions].map((e) => {
						return {
							...e,
							selected: [],
							isRequired: e.isRequired ? e.isRequired : false,
							error: false,
						};
					});
					setQuestions(questions);
					setQuizTag(res.data.tag ? res.data.tag : "");
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log("Response Error:", JSON.stringify(err));
				setSurveyStatus({ status: false, activityEventId: "none" });
				setLoading(false);
			});

		// eslint-disable-next-line
	}, []);
	const errorMessages = {
		npnAnswer: { invalidMsg: "Should be maximum 750 characters" },
	};
	const quizStart = async () => {
		if (isPointsApiCalled) {
			return false;
		}

		setIsPointsApiCalled(true);
		let data = {
			reference: id,
			state: "started",
		};
		props
			.pointsUpdate(data, "post")
			.then((responseData) => {
				setSurveyStatus({ ...surveyStatus, status: true, activityEventId: responseData.data.id });
			})
			.catch((err) => {
				console.log("Response Error:", JSON.stringify(err));
				setSurveyStatus({ ...surveyStatus, status: false });
			});
	};
	const getTotalEntriesCount = async () => {
		const activityStatusRes = await getCompletedActivity([activityData.id]);
		if (activityStatusRes?.data) {
			const count = activityStatusRes.data.length > 0 ? activityStatusRes.data[0].todayEntry[0]?.count : 0;
			if (count > 0) {
				setDailyEntries(activityStatusRes.data.length > 0 ? activityStatusRes.data[0].todayEntry[0]?.count : 0);
			} else {
				setDailyEntries("N/A");
			}
			console.log("DailyEntries", dailyEntries);
			const totalCount = activityStatusRes.data.length > 0 ? activityStatusRes.data[0].campaignDuration[0]?.count : 0;
			if (totalCount > 0) {
				setTotalEntries(activityStatusRes.data.length > 0 ? activityStatusRes.data[0].campaignDuration[0]?.count : 0);
			} else {
				setTotalEntries("N/A");
			}
		}
	};

	const handleAnswers = async (e, text) => {
		e.persist();
		quizStart();

		// return errors;
		let [questionId, feedback] = text === "text" ? [e.target.id, e.target.value] : e.target.value.split("-");
		feedback = feedback.trim();
		const newQuestions = [...questions];
		const questionIndex = questions.findIndex((o) => o.id === questionId);
		const { type } = newQuestions[questionIndex];
		switch (type) {
			case "radio":
			case "boolean":
			case "text":
				newQuestions[questionIndex] = { ...newQuestions[questionIndex], selected: feedback === "" ? [] : [feedback], error: false };
				break;
			case "checkbox":
				const selected = newQuestions[questionIndex].selected ? [...newQuestions[questionIndex].selected] : [];
				selected.indexOf(feedback) === -1 ? selected.push(feedback) : !e.target.checked && selected.splice(selected.indexOf(feedback), 1);
				newQuestions[questionIndex]["error"] = newQuestions[questionIndex].isRequired && selected.length === 0;
				newQuestions[questionIndex] = { ...newQuestions[questionIndex], selected: [...selected] };
			default:
				break;
		}
		setQuestions(newQuestions);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		let error = false;
		let data = {
			reference: id,
			state: "started",
		};
		let payload = {
			activityId: activityData.id,
			surveyId: activityData.data,
			poitsHistoryId: surveyStatus.activityEventId,
			userFeedback: [],
			tag: "BIMBO_SWEEPSTACKS",
		};

		const newQuestions = [...questions];
		for (var i in newQuestions) {
			if (newQuestions[i].isRequired && newQuestions[i].selected?.length === 0) {
				newQuestions[i].error = true;
				error = true;
			} else if (newQuestions[i].selected[0].length < 250 || newQuestions[i].selected[0].length > 750) {
				newQuestions[i].error = true;
				error = true;
			}

			payload.userFeedback.push({
				id: newQuestions[i].id,
				answer: newQuestions[i].selected,
			});
		}

		if (!error) {
			try {
				const activityStatusRes = await getCompletedActivity([activityData.id]);
				if (activityStatusRes?.data) {
					const count = activityStatusRes.data.length > 0 ? activityStatusRes.data[0].todayEntry[0]?.count : 0;
					const totalCount = activityStatusRes.data.length > 0 ? activityStatusRes.data[0].campaignDuration[0]?.count : 0;
					setTotalEntries(activityStatusRes.data.length > 0 ? activityStatusRes.data[0].campaignDuration[0]?.count : 0);
					if (count >= activityData.times) {
						toast.error(t("NPM.info2"));
						history.push("/");
					} else if (totalCount > activityData.maxAttempt) {
						toast.error(t("NPM.info2"));
						history.push("/");
					} else {
						if (payload.poitsHistoryId === "none") {
							let pointResData = await props.pointsUpdate(data, "post");
							payload["poitsHistoryId"] = pointResData?.data?.id;
						}
						userSurveyFeedback(payload)
							.then((e) => {
								if (e.httpCode >= 200 && e.httpCode < 300) {
									toast.success(t("NPM.success"));
									let feedback = {};
									const feedbackRes = e.data?.feedBack;
									if (feedbackRes && feedbackRes.length > 0) {
										feedbackRes.forEach((ans) => {
											feedback[ans.questionId] = ans.correctAnswer;
										});
										setAnswers(feedback);
									}
									if (["Poll", "Survey"].indexOf(quizTag) >= 0) {
										history.push("/");
									}
								}
								setLoading(false);
							})
							.catch((error) => {
								console.log(error);
								setLoading(false);
								toast.error(t(`COMMON.SOMTHING_WRONG`));
							});
					}
				} else {
					setLoading(false);
					toast.error(t(`COMMON.SOMTHING_WRONG`));
				}
			} catch (error) {
				console.log(error);
				setLoading(false);
				toast.error(t(`COMMON.SOMTHING_WRONG`));
			}
		} else {
			setQuestions(newQuestions);
			setLoading(false);
		}
	};
	return (
		<>
			<div className="user-pages">
				<div className="page-section">
					<div className="portal-point-list-container py-5">
						<Container fluid>
							<Row>
								<form className="col-12 py-5">
									<div className="questions-card">
										{questions.map((question) => {
											return (
												<div className="card">
													<h1 className="section-heading heading text-center">
														<Trans>NPM.heading1</Trans>
													</h1>

													<div class="float-container py-5 text-center">
														<div class="float-child black-bg">
															<span>
																<Trans>NPM.subHeading1</Trans>
															</span>
														</div>
														{/* <div class="float-child text-left pl-2">
															<span>{totalEntries}</span>
														</div> */}
													</div>
													<>
														{/* {totalEntrie > ? (): (<></>  )} */}

														{dailyEntries > 0 ? (
															<>
																<Row className="justify-content-md-center text-center" id="participate-content" aria-label="content">
																	<Col md={9}>
																		<div className="text-center">
																			<p className="input-error npn-msg" role="alert">
																				<i>
																					<Trans>NPM.info2</Trans>
																					<br></br>
																				</i>
																			</p>
																			<p className="mt-5" dangerouslySetInnerHTML={{ __html: t("NPM.tnc") }} />

																			<a href="/upload-receipt" className="btn btn-primary upload-btn">
																				<Trans>UPLOAD RECEIPT</Trans>
																			</a>
																		</div>
																	</Col>
																</Row>
															</>
														) : (
															<>
																<FormControl key={question.id} className="w-100">
																	<FormLabel className="mb-2" id="q1-radio-buttons-group-label">
																		{/* {question.title} */}
																		<Trans>NPM.Question1</Trans>
																		{question.isRequired ? <Required /> : ""}
																	</FormLabel>
																	{question.type === "text" && <textarea name="npnAnswer" maxLength={750} id={question.id} label="Your answer" placeholder="" rows={10} cols={40} multiline onChange={(e) => handleAnswers(e, "text")} />}
																	<p>
																		<i>
																			<Trans>NPM.info</Trans>
																		</i>
																	</p>
																	{question.error && (
																		<div className="input-error" role="alert">
																			<Trans>NPM.reqMsg</Trans>
																		</div>
																	)}
																	<div className="mt-4 text-center">
																		<button type="submit" role="submit" className="btn btn-primary btn-submit" id="survey-submit-btn" onClick={onSubmit}>
																			<Trans>NPM.Submit</Trans>
																		</button>
																	</div>
																</FormControl>
															</>
														)}
													</>
												</div>
											);
										})}
									</div>
								</form>
							</Row>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};
export default React.memo(connect(null, { pointsUpdate, surveyDetail })(IsLoadingHOC(QuizQuestions, "Loading....")), (prevProps, nextProps) => true);
