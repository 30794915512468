import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ThankYou = (props) => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: "auto",
	});
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<div className="thankspage py-5">
				<div className="container">
					<Row className="justify-content-center">
						<Col className="col-sm-8">
							<h1 className=" mt-5">{t("Thank_you")}</h1>
							<h2 className="">{t("Your receipt has been received")}</h2>
							<div>
								<p className="mt-5 text-center">{t("thank_p_one")}</p>
								<p className="mt-4 text-center">
									{t("thank_p_two_a")} <a href="mailto:support@receiptprocessor.com">{t("support_receiptprocessor_com")}</a> {t("thank_p_two_b")}
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default ThankYou;
