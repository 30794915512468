import React from "react";
import Signup from '../../components/Signup';
const SignupScreen = (props) => {
	return (
		<>
			<Signup />
		</>
	);
};

export default SignupScreen;
